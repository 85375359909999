import React, {useEffect, useState} from "react";
import { motion, useSpring } from "framer-motion";
import './Gallery.css';

export function ImageDescription({imageData}) {

    const translate = useSpring(100,{ stiffness: 90, damping: 15 });

    const [mousePos, setMousePos] = useState({});
    const [mouseMove, setMouseMove] = useState(false);

    useEffect(() => {
        translate.set(0);
        const timeoutId = setTimeout(() => {
            translate.set(150);
            setMouseMove(false);
            }, 4000);
            return () => clearTimeout(timeoutId);
    },[imageData, mouseMove]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
      setMouseMove(true);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, [mousePos]);


    return(
        <motion.div 
        style={{y: translate}}
        className="descriptionContainer" >
            <span className="imageTitle">{imageData.title}</span>
            <span className="imageType">{imageData.type}</span>
            <span className="imageDescription">{imageData.description}</span>
        </motion.div>
    )
}