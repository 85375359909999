import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import './About.css';

//Media imports
import DVFX from '../../Media/Gallery/logo/DVFX.png';
import CiakNEROsenzalinee from '../../Media/ciak/CiakNEROsenzalinee.png'
import ManoMayfair_nanaHigh_10tillLate_banner from '../../Media/VFX/Preview/ManoMayfair_nanaHigh_10tillLate_banner.png';
import Space_Ship_Arcade_Keying_SpaceInvaders_banner from '../../Media/VFX/Preview/Space_Ship_Arcade_Keying_SpaceInvaders_banner.png'

//Component imports
import { ContactForm } from '../Contact/ContactForm';

//Icons import 
import {FaSquareXTwitter, FaImdb, FaLinkedin} from 'react-icons/fa6';
import {TbBrandFiverr} from 'react-icons/tb';


export function AboutSection({mousePos}) {

    //Icons and links

    const socials = [
        {icon: <FaImdb className="contactCardIcon"/>, link: 'https://m.imdb.com/name/nm13517117/', name: 'Imdb'},
        {icon: <FaLinkedin className="contactCardIcon"/>, link: 'https://www.linkedin.com/in/davide-d-antonio-18312b92?originalSubdomain=uk', name: 'Linkedin'},
        {icon: <TbBrandFiverr className="contactCardIcon"/>, link: ' https://www.fiverr.com/davedantart?up_rollout=true', name: 'Fiverr'},
        {icon: <FaSquareXTwitter className="contactCardIcon"/>, link: ' https://twitter.com/davide_dantoni0', name: 'X'}
    ];

    const [selectedTab, setSelectedTab] = useState('socials');

    return(
        <motion.div 
        initial={{opacity:0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.4}}
        className='mainContainer'>
            <div className='bannerContainer'
            style={{
                backgroundImage: `url(${ManoMayfair_nanaHigh_10tillLate_banner})`,
                backgroundSize: 'cover'
            }}
            >
                <motion.h2 
                initial={{y: 200}}
                animate={{y:0}}
                transition={{duration: 1.2, type: 'spring'}}
                className="VFXTitle">about me</motion.h2>    
            </div>
            <div className='contactContainer'>
            <div className='selfDescrContainer'>
                    <span className='selfDescContent'>
                        A born fine artist , evolved into a Visual Effects Artist with 3 years of compositing, 6 years of roto/prep, and over a decade of  hand painting and illustration skills. I am currently working in Special Effects and have contributed to projects and movies for Disney, Marvel, Netflix, to name a few. Find more about my projects, blending technical skills with a passion for captivating paintings with a unique touch.
                    </span>
                </div>
                <span className='selfDescTitle'>get in touch</span>
                <div className='switchContainer'>
                  <div 
                  onClick={() => {
                    selectedTab !== 'socials' && setSelectedTab('socials')
                  }}
                  className='tabContainer'>
                    <span 
                    style={{color: selectedTab === 'socials' ? 'rgb(24,24,24)' : 'rgb(240,240,240)'}}
                    className='tabText'>Socials</span>
                    {
                        selectedTab === 'socials' &&
                        <motion.div 
                        transition={{type: 'spring', duration: 0.7}}
                        layoutId='selection' className='selection'></motion.div>
                    }
                  </div>
                  <div 
                  onClick={() => {
                    selectedTab !== 'message' && setSelectedTab('message')
                  }}
                  className='tabContainer'>
                    <span
                    style={{color: selectedTab === 'message' ? 'rgb(24,24,24)' : 'rgb(240,240,240)'}}
                    className='tabText'>Message</span>
                    {
                        selectedTab === 'message' && 
                        <motion.div 
                        transition={{type: 'spring', duration: 0.7}}
                        layoutId='selection' className='selection'></motion.div>
                    }
                  </div>
                </div>
                <motion.div 
                className="flip-card">
                    <motion.div
                    style={{transform: selectedTab === 'message' ? 'rotateY(180deg)' : 'rotateY(0deg)'}}
                    className="flip-card-inner">
                        <div 
                        style={{backgroundImage: `url(${CiakNEROsenzalinee})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                        className="flip-card-front">
                            <div 
                            className='left'>
                                <img className="contactCardLogo" src={DVFX} alt='logo'/>
                                <span className='contactCardAuthor'>Davide D'Antonio</span>
                                <span className='contactCardSecondary'>VFX Artist</span>
                            </div>
                            <div className='right'>
                                {socials.map((social, index) => (
                                    <div 
                                    key={index}
                                    className='contactCardSocial'>
                                        <a 
                                        key={index}
                                        href={social.link}
                                        target='_blank'
                                        className="contactCardIcon">
                                            {social.icon}
                                            <span className='socialName'>{social.name}</span>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div 
                        style={{backgroundImage: `url(${CiakNEROsenzalinee})`, backgroundSize: 'cover',}}
                        className="flip-card-back">
                            <ContactForm />
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </motion.div>
    )
}