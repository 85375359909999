import React, { useEffect, useState, useRef } from "react";
import { motion, useSpring} from "framer-motion";
//Posters import 
import Quiz from '../../Media/movie_posters/Quiz.jpg';
import Our_Girl from '../../Media/movie_posters/Our_Girl.jpg';
import The_Great from '../../Media/movie_posters/The_Great.jpg';
import Flack from '../../Media/movie_posters/Flack.jpg';
import Cursed from '../../Media/movie_posters/Cursed.jpg';
import The_Great_2 from '../../Media/movie_posters/The_Great_2.jpg';
import The_Northman from '../../Media/movie_posters/The_Northman.jpg';
import The_Man_Who_Fell_To_Earth from '../../Media/movie_posters/The_Man_Who_Fell_To_Earth.jpg';
import Bridgerton from '../../Media/movie_posters/Bridgerton.jpg';
import Fantastic from '../../Media/movie_posters/Fantastic.jpg';
import Wendell_and_Wild from '../../Media/movie_posters/Wendell_and_Wild.jpg';
import Thor from '../../Media/movie_posters/Thor.jpg';
import The_Sandman from '../../Media/movie_posters/The_Sandman.png';
import Secret_Invasion from '../../Media/movie_posters/Secret_Invasion.jpg';
import Damsel from '../../Media/movie_posters/Damsel.jpg';
import Napoleon from '../../Media/movie_posters/Napoleon.jpeg';
import Constellation from '../../Media/movie_posters/Constellation.jpg'
import The_Book_of_Clarence from '../../Media/movie_posters/The_Book_of_Clarence.jpg'
import Madame_Web from '../../Media/movie_posters/Madame_Web.jpg';
import Beetlejuice2 from '../../Media/movie_posters/Beetlejuice2.png'
import { useInView } from "@react-spring/web";


const postersSrc = [
    Beetlejuice2,
    Damsel,
    Madame_Web,
    Constellation,
    Napoleon,
    The_Book_of_Clarence,
    Secret_Invasion,
    The_Sandman,
    Thor,
    Wendell_and_Wild,
    Fantastic,
    Bridgerton,
    The_Man_Who_Fell_To_Earth,
    The_Northman,
    The_Great_2,
    Cursed,
    Flack,
    The_Great,
    Our_Girl,
    Quiz
]

export function PosterTile({posterData, index}) {

    const config = {
        stiffness: 110,
        damping: 15
    }

    const [hover,setHover] = useState(false);
    const translate = useSpring(100, config);
    const translateTitle = useSpring(-100,config);
    const opacity = useSpring(100,config);
    const alpha = useSpring(0, config);

    useEffect(() => {
        if(hover){
            translate.set(0);
            translateTitle.set(0)
            opacity.set(1);
            alpha.set(0.8);
        } else if(!hover) {
            translate.set(100);
            translateTitle.set(-100)
            opacity.set(0);
            alpha.set(0);
        }
    },[hover]);


    return(
        <motion.div
            style={{backgroundImage: `url(${postersSrc[index]})`}}
            className="posterContainer">
            <motion.div 
            style={{backgroundColor: hover ? `rgba(0, 0, 0, 0.8)` : 'rgba(0, 0, 0, 0)'}}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className="hoverContent">
                <motion.span 
                style={{y: translateTitle, opacity}}
                className="posterTitle">{posterData.title}</motion.span>
                <div className="bottomTile">
                    <motion.span 
                    style={{y: translate, opacity}}
                    transition={{delay: 0.5}}
                    className="platform">{posterData.platform}</motion.span>
                    <motion.span
                    style={{y: translate, opacity}}
                    className="secondary">{posterData.studio}</motion.span>
                    <motion.span 
                    style={{y: translate, opacity}}
                    className="secondary">{posterData.role}</motion.span>
                    <motion.div 
                    style={{y: translate, opacity}}
                    className="inlinePoster">
                        <motion.span>{posterData.episodes}</motion.span>
                        <motion.span>{posterData.year}</motion.span>
                    </motion.div>
                </div>

            </motion.div>    
        </motion.div>
    )
}