import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useLocation, useParams } from "react-router-dom";
//Videos import
import DavideComp_Reel_2023_v01 from '../../Media/VFX/DavideComp_Reel_2023_v01.mp4';
import PolpoCuore_Colore from '../../Media/VFX/PolpoAlCuore.mp4';
import TransmissionSBT from '../../Media/VFX/TransmissionSBT.mp4';
import Space_Ship_Arcade_Keying_SpaceInvaders from '../../Media/VFX/Space_Ship_Arcade_Keying_SpaceInvaders.mp4';
import Original from '../../Media/VFX/Restoring Old PIC/Original.jpg';
import Edited from '../../Media/VFX/Restoring Old PIC/Edited.png';
import ManoMayfair_nanaHigh_10tillLate from '../../Media/VFX/ManoMayfair_nanaHigh_10tillLate.mp4';
//Banner imports
import DavideComp_Reel_2023_v01_banner from '../../Media/VFX/Preview/DavideComp_Reel_2023_v01_banner.png';
import PolpoAlCuore_banner from '../../Media/VFX/Preview/PolpoAlCuore_banner.png';
import TransmissionSBT_banner from '../../Media/VFX/Preview/TransmissionSBT_banner.png'
import ManoMayfair_nanaHigh_10tillLate_banner from '../../Media/VFX/Preview/ManoMayfair_nanaHigh_10tillLate_banner.png'
import Space_Ship_Arcade_Keying_SpaceInvaders_banner from '../../Media/VFX/Preview/Space_Ship_Arcade_Keying_SpaceInvaders_banner.png'
//Cover Imports
import DavideComp_Reel_2023_v01_poster from '../../Media/VFX/Preview/Davide_Comp_poster.png';
import PolpoAlCuore_poster from '../../Media/VFX/Preview/PolpoAlCuore_poster.png';
import TransmissionSBT_poster from '../../Media/VFX/Preview/Trasmettitore_poster.png'
import ManoMayfair_nanaHigh_10tillLate_poster from '../../Media/VFX/Preview/Mano_poster.png'
import Space_Ship_Arcade_Keying_SpaceInvaders_poster from '../../Media/VFX/Preview/Space_Ship_Arcade_SpaceInvaders_poster.png';

export function VideoSection() {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const srcs = [
        {src: DavideComp_Reel_2023_v01, type: 'video/mp4', banner: DavideComp_Reel_2023_v01_banner, poster: DavideComp_Reel_2023_v01_poster},
        {src: PolpoCuore_Colore, type: 'video/mp4', banner: PolpoAlCuore_banner, poster: PolpoAlCuore_poster},
        {src: Space_Ship_Arcade_Keying_SpaceInvaders, type: 'video/mp4', banner: Space_Ship_Arcade_Keying_SpaceInvaders_banner, poster: Space_Ship_Arcade_Keying_SpaceInvaders_poster},
        {src: TransmissionSBT, type: 'video/mp4', banner: TransmissionSBT_banner, poster: TransmissionSBT_poster},
        {src: ManoMayfair_nanaHigh_10tillLate, type: 'video/mp4', banner: ManoMayfair_nanaHigh_10tillLate_banner, poster: ManoMayfair_nanaHigh_10tillLate_poster},
        //{src: Original, type: 'img', cover: ''},
    ];

    const {id} = useParams();
    const location = useLocation();
    const data = location.state.data;
    const element = srcs[id];

    return(
        <motion.div 
        initial={{opacity:0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.4}}>
            <div 
            style={{
                backgroundImage: `url(${element?.banner})`,
                backgroundSize: 'cover'
            }}
            className="bannerContainer">
                <motion.h2 
                initial={{y: 200}}
                animate={{y:0}}
                transition={{duration: 1.2, type: 'spring'}}
                className="VFXTitle">{data.title}</motion.h2>    
            </div>
            <div className="detailsSection">
                <div className="category">
                    <span className="descriptionTitle">category</span>
                    <span className="space">-</span>
                    {
                        data.categories.map((el, index) => (
                            <span key={index} className="catList">{el}</span>
                        ))
                    }
                </div>
                <div className="description">
                    <span className="descriptionTitle">project</span>
                    <span className="space">-</span>
                    <span className="descrBody">{data.description}</span>
                </div>
            </div>
            {
                id &&
                <div className="videoPlayerContainer">
                    <video 
                    poster={element?.poster}
                    className="videoPlayer"
                    controls>
                        <source 
                        src={element?.src} type={element?.type}/>
                    </video>
                </div>
            }
        </motion.div>
    )
}