import React, { useEffect, useState} from "react";
import './Gallery.css'; 
import { motion, useSpring, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
//Images import 
import HumanHAnd from '../../Media/Gallery/HumanHAnd.jpg'
import Davide_Ale from '../../Media/Gallery/Davide_Ale.jpg'
import MandaloriaBuddha from '../../Media/Gallery/MandaloriaBuddha.jpg'
import Nudenoir_v01 from '../../Media/Gallery/Nudenoir_v01.jpg'
import NudeNoir_v02 from '../../Media/Gallery/NudeNoir_v02.jpg'
import NudeNoir_v03 from '../../Media/Gallery/NudeNoir_v03.jpg'
import NudeNoir_v04 from '../../Media/Gallery/NudeNoir_v04.jpg'
import NudeNoir_v05 from '../../Media/Gallery/NudeNoir_v05.jpg'
import NudeNoir_v06 from '../../Media/Gallery/NudeNoir_v06.jpg'
import NudeNoir_v07 from '../../Media/Gallery/NudeNoir_v07.jpg'
import Orc from '../../Media/Gallery/Orc.png'
import Stella from '../../Media/Gallery/Stella.png'
import ZombieApocalyps2 from '../../Media/Gallery/ZombieApocalyps2.png'
import AlienStudy from '../../Media/Gallery/AlienStudy.jpg'
import Haeiz from '../../Media/Gallery/Haiez.jpg'
import Monst from '../../Media/Gallery/Monst.jpg'
import Nouveau from '../../Media/Gallery/Nouveau.jpg'
import Scientist from '../../Media/Gallery/Scientist.png'
import Seahorse from '../../Media/Gallery/Seahorse.jpg'
import SketcMad from '../../Media/Gallery/SketcMad.jpg'
//Icons import 
import { AiOutlineClose} from 'react-icons/ai';
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
//Loading spinner import 
import { RotatingLines } from  'react-loader-spinner'
//Data import
import { data } from "../../Media/data";
import { ImageDescription } from "./ImageDescription";

export function ExpandedImage({selectedId, setSelectedId, imgPos}) {

  const srcs = [
        //Davide_Ale,
        Nudenoir_v01,
        NudeNoir_v02,
        NudeNoir_v03,
        NudeNoir_v04,
        NudeNoir_v05,
        NudeNoir_v06,
        NudeNoir_v07,
        Stella,
        MandaloriaBuddha,
        HumanHAnd,
        Monst,
        Haeiz,
        Nouveau,
        AlienStudy,
        Seahorse,
        SketcMad,
        Scientist,
        Orc,
        ZombieApocalyps2
  ];

  const variants = {
        enter: (direction) => {
          return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
          };
        },
        center: {
          zIndex: 1,
          x: 0,
          opacity: 1
        },
        exit: (direction) => {
          return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
          };
        }
      };

      const [[page, direction], setPage] = useState([selectedId-1, 0]);
      const [load, setLoad] = useState(false)

      const imageIndex = wrap(0, srcs.length, page);


        const paginate = (newDirection) => {
            setPage([page + newDirection, newDirection]);
        };
      
      const swipeConfidenceThreshold = 10000;
      const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
      };

      useEffect(() => {
        setLoad(true);
        setZoomed(false)
      },[selectedId, imageIndex]);

      const imageData = data.gallery[imageIndex];

      useEffect(() => {
        selectedId && window.scrollTo({
          top: imgPos[imageIndex],
          left: 0,
          behavior: 'auto'
        });
      }, [imageIndex]);

    const [zoomed, setZoomed] = useState(false);
    const [mousePos, setMousePos] = useState({});

    useEffect(() => {
      const handleMouseMove = (event) => {
        setMousePos({ x: event.clientX, y: event.clientY });
      };
  
      window.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        window.removeEventListener(
          'mousemove',
          handleMouseMove
        );
      };
    }, [mousePos]);

    function handleZoom() {
      const dimension = window.innerWidth;
      dimension > 825 &&
      setZoomed(!zoomed)
    }

    return(
        <motion.div 
        animate={{opacity: 1}}
        initial={{opacity: 0}}
        transition={{duration: 0.6}}
        className="ExpandedImageContainer"
        >
            <motion.div 
            initial={{left: -100, opacity: 0}}
            animate={{left: 0, opacity: 1}}
            transition={{duration: 0.6}}
            onClick={() => paginate(-1)}
            className="fixedLeft">
                <FaCaretLeft className="controlIcon"/>
            </motion.div>
            <motion.div 
            initial={{right: -100, opacity: 0}}
            animate={{right: 0, opacity: 1}}
            transition={{duration: 0.6}}
            className="fixedRight">
                <div  
                style={{background: 'none'}}
                onClick={() => paginate(1)}>
                <FaCaretRight className="controlIcon" />
                </div>
            </motion.div>
            <motion.div 
            initial={{scale: 0, opacity: 0}}
            animate={{scale: 1, opacity: 1}}
            transition={{duration: 0.4}}
            onClick={() => setSelectedId(null)}
            className="closeBtn">
                <AiOutlineClose className="closeIcon"/>
            </motion.div>
            {
              load && 
              <RotatingLines
                strokeColor="lightgrey"
                strokeWidth="4"
                animationDuration="0.75"
                width="30"
                visible={true}
              />
            }
            <motion.img
            onClick={() => handleZoom()}
            style={{cursor: 'zoom-in', scale: zoomed ? 2 : 1, cursor: zoomed ? 'zoom-out' : 'zoom-in', transformOrigin: `${mousePos.x}px ${mousePos.y}px` }}
            onLoad={() => setLoad(false)}
            className="expandedImage"
            key={page}
            src={srcs[imageIndex]}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            alt='gallery image'
            transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.4 }
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
                } else if (swipe > swipeConfidenceThreshold - 500) {
                paginate(-1);
                }
            }}
            />
            <ImageDescription imageData={imageData} />
        </motion.div>
    )
}