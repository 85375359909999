import React from 'react'
import { motion } from 'framer-motion'
import './VfxSection.css';
//Components import
import { Tile } from './Tile';
//Previews Import 
import DavideComp_Reel_2023_v01_coverA from '../../Media/VFX/Preview/DavideComp_Reel_2023_v01_coverA.png';
import DavideComp_Reel_2023_v01_coverB from '../../Media/VFX/Preview/DavideComp_Reel_2023_v01_coverB.gif';
import PolpoCuore_Colore_coverA from '../../Media/VFX/Preview/PolpoCuore_Colore_coverA.png';
import PolpoCuore_Colore_coverB from '../../Media/VFX/Preview/PolpoCuore_Colore_coverB.gif';
import Space_Ship_Arcade_Keying_SpaceInvaders_coverA from '../../Media/VFX/Preview/Space_Ship_Arcade_Keying_SpaceInvaders_coverA.png';
import Space_Ship_Arcade_Keying_SpaceInvaders_coverB from '../../Media/VFX/Preview/Space_Ship_Arcade_Keying_SpaceInvaders_coverB.gif';
import TransmissionSBT_coverA from '../../Media/VFX/Preview/TransmissionSBT_coverA.png';
import TransmissionSBT_coverB from '../../Media/VFX/Preview/TransmissionSBT_coverB.gif';
import Original from '../../Media/VFX/Preview/Original.jpg';
import Edited from '../../Media/VFX/Preview/Edited.png';
import ManoMayfair_nanaHigh_10tillLate_coverA from '../../Media/VFX/Preview/ManoMayfair_nanaHigh_10tillLate_coverA.png';
import ManoMayfair_nanaHigh_10tillLate_coverB from '../../Media/VFX/Preview/ManoMayfair_nanaHigh_10tillLate_coverB.gif';
//Data import
import { data } from '../../Media/data';


export function VfxSection() {

    const VidElem = [
        [DavideComp_Reel_2023_v01_coverA, DavideComp_Reel_2023_v01_coverB, 'Personal Compositing Reel'],
        [PolpoCuore_Colore_coverA, PolpoCuore_Colore_coverB, 'Polpo Al Cuore'],
        [Space_Ship_Arcade_Keying_SpaceInvaders_coverA, Space_Ship_Arcade_Keying_SpaceInvaders_coverB, 'Space Ship Arcade'],
        [TransmissionSBT_coverA, TransmissionSBT_coverB, 'Transmission SBT'],
        [ManoMayfair_nanaHigh_10tillLate_coverA, ManoMayfair_nanaHigh_10tillLate_coverB, 'Social Media Reel'],
        //[Original, Edited, 'Restoring Old Pic'],
    ];

    return(
        <motion.div
        initial={{opacity:0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.4}}
        className='VFXContainer'
        >
            <div className='tilesContainer'>
                {
                    VidElem.map((element, index) => (
                        <Tile key={index} index={index} element={element} data={data.VFX[index]}/>
                    ))
                }
            </div>
        </motion.div>
    )
}