import React, {useEffect, useState, useRef} from 'react'
import './Gallery.css';
import { data } from '../../Media/data';
import { motion, AnimatePresence } from "framer-motion";
//Components import
import { GalleryImage } from './GalleryImage';
import { ExpandedImage } from './ExpandedImage';

export function GallerySection({ setLoading, setExpandedProj}) {

    const [selectedId, setSelectedId] = useState(null);
    const [imgPos, setImgPos] = useState([]);
    //const [lastSeen, setLastSeen] = useState(null);

    useEffect(() => {
        selectedId ? setExpandedProj(true) : setExpandedProj(false);
    },[selectedId]);

    return(
        <motion.div 
        onLoad={() => setLoading(false)}
        initial={{opacity:0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.4}}
        className='galleryContainer'>
            {
                data.gallery.map((el,index) => (
                    <GalleryImage 
                    setImgPos={setImgPos}
                    key={index}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    element={el} index={index}/>
                )
                )
            }
            {
                selectedId &&
                <ExpandedImage 
                imgPos={imgPos}
                selectedId={selectedId} 
                setSelectedId={setSelectedId}
                />
            }
        </motion.div>
    )
}