import './App.css';
import { NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
//Components import
import { Home } from './Components/Home/Home.jsx';
import {AboutSection} from './Components/About/AboutSection.jsx'
import { GallerySection } from './Components/Gallery/GallerySection';
import { VfxSection } from './Components/VFX/VfxSection';
import { NavBar } from './Components/NavBar/NavBar';
import { TopButton } from './Components/NavBar/TopButton';
import { VideoSection } from './Components/VFX/VideoSection';
import { Footer } from './Components/Footer/Footer.jsx';
import { Loader } from './Components/Loader/Loader.jsx';

function App() {

  const [expandedProj, setExpandedProj] = useState(false);

  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo({
      top:0,
    })
  },[pathname]);

  useEffect(() => {
    if(expandedProj) {
      document.body.style.overflow = 'hidden' 
    } else {
      document.body.style.overflow = 'auto' 
    }
  },[expandedProj]);

  const [loading, setLoading] = useState(true);
  
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path='/' element={<Home setLoading={setLoading}/>}/>
        <Route path='/gallery' element={<GallerySection setLoading={setLoading} setExpandedProj={setExpandedProj}/>}/>
        <Route path='/VFX' element={<VfxSection setLoading={setLoading}/>}/>
        <Route path='/contact' element={<AboutSection setLoading={setLoading}/>}/>
        <Route path='/VFX/:id' element={<VideoSection setLoading={setLoading}/>}/>
      </Routes>
      <TopButton expandedProj={expandedProj} />
      <Footer />
      <Loader loading={loading} setLoading={setLoading}/>
    </div>
  );
}

export default App;
