import React, {useEffect, useState} from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { motion, useSpring, animate} from "framer-motion"
import './NavBar.css'
//Icons import 
import {FaSquareXTwitter, FaImdb, FaLinkedin} from 'react-icons/fa6';
import {TbBrandFiverr} from 'react-icons/tb';
import DVFX from '../../Media/Gallery/logo/DVFX.png';

export function NavBar() {

    const tabs = [
        {
            name: 'Home',
            pathname: '/',

        },
        {
            name: 'Gallery',
            pathname: '/gallery',
            
        },
        {
            name: 'VFX',
            pathname: '/VFX',
            
        },
        {
            name: 'Contact',
            pathname: '/contact',
            
        }
    ]

    //Icons and links

    const socials = [
        {icon: <FaImdb className="icon"/>, link: 'https://m.imdb.com/name/nm13517117/'},
        {icon: <FaLinkedin className="icon"/>, link: 'https://www.linkedin.com/in/davide-d-antonio-18312b92?originalSubdomain=uk'},
        {icon: <TbBrandFiverr className="icon"/>, link: ' https://www.fiverr.com/davedantart'},
        {icon: <FaSquareXTwitter className="icon"/>, link: ' https://twitter.com/davide_dantoni0'}
    ];

    //Spring Menu Values
    const rotateTop = useSpring(0);
    const rotateBottom = useSpring(0);
    const translateTop = useSpring(0);
    const opacityMiddle = useSpring(0);
    const translateBottom = useSpring(0);
    const menuTranslate = useSpring(0);
    const menuOpacity = useSpring(0);

    //Hamburgher menu state
    const [isOpen, setIsOpen] = useState(false);
    
    //Close Menu once location is changed
    const {pathname} = useLocation();
    useEffect(() => {
        setIsOpen(false);
    },[pathname])

    //Hamburgher animation values
    useEffect(() => {
        if(isOpen) {
            document.body.style.overflow = 'hidden';
            opacityMiddle.set(0); 
            rotateTop.set(45);
            translateTop.set(9);
            rotateBottom.set(-45);
            translateBottom.set(-9);
            menuOpacity.set(1);
            menuTranslate.set(0)
        } else if(!isOpen) {
            document.body.style.overflow = 'auto' 
            opacityMiddle.set(1); 
            rotateTop.set(0);
            translateTop.set(0);
            rotateBottom.set(0);
            translateBottom.set(0);
            menuOpacity.set(0);
            menuTranslate.set(150)

        }
    },[isOpen])

    const location = useLocation();
    const index = location.state?.index;
    let VFXPath = location.pathname == `/VFX/${index}` ? true : false;
    let home = location.pathname === `/` ? true : false;

    //Shared underline layout transition

    //Set the correct selected tab on reload based on location pathname
    const [selectedTab, setSelectedTab] = useState(null);
    useEffect(() => {
        let {pathname} = location;
        switch(pathname) {
            case '/':
                setSelectedTab('Home');
            break;
            case '/gallery':
                setSelectedTab('Gallery');
            break;
            case '/VFX':
                setSelectedTab('VFX');
            break;
            case '/contact': 
                setSelectedTab('Contact');
            break;
            default: 
                setSelectedTab('Home');
            break;
        }
    },[pathname]);

    const navigate = useNavigate();

    return(
        <>
        <motion.div 
        className="navBarContainer">
            <div className="navBarSubSection" id="authorSection">
                <motion.img 
                alt='logo'
                onClick={() => navigate('/')}
                initial={{scale: 0, height: 0, width: 0, filter: 'blur(12px)', x: 20}}
                animate={{scale: 1, height: 36.8, width: 36.8, filter: 'blur(0px)', x: 0}}
                transition={{type: 'spring', duration: 1.3,  delay: 3.5}}
                className="logo"
                src={DVFX} />
                <motion.span 
                initial={{scale: 1,filter: 'blur(0px)', x:0, fontSize: '22.4px'}}
                animate={{scale: 0, filter: 'blur(12px)', x: -50, fontSize: '0px'}}
                transition={{type: 'spring', duration: 1.5, delay: 3.2}}
                className="author">Davide D'Antonio</motion.span>
            </div>
            <div 
            onClick={() => setIsOpen(!isOpen)}
            className="hamburgherContainer">
                <motion.div style={{rotate: rotateTop, translateY: translateTop }} className="bar"></motion.div>
                <motion.div style={{opacity: opacityMiddle}} className="bar"></motion.div>
                <motion.div style={{rotate: rotateBottom, translateY: translateBottom}} className="bar"></motion.div>
            </div>
            <motion.div 
            className="navBarSubSection" id='navLinks'>
                {
                    tabs.map(tab => (
                        <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}} key={tab.name}>
                            <NavLink to={tab.pathname}
                            className={({isActive, isPending}) => isActive ? 'activeLink' : isPending ? '' : 'link'}>{tab.name}</NavLink>
                            {selectedTab === tab.name ? 
                            <motion.div 
                            className="underline" 
                            style={{scale: 0, filter: 'blur(10px)'}}
                            animate={{scale: 1, filter: 'blur(0px)'}}
                            transition={{type: 'spring', bounce: 0.20, duration: 0.8}}
                            ></motion.div> 
                            : null}
                        </div>
                    ))
                }
                <motion.div
                className="socialContaner">
                    <div className="iconsContainer">
                    {
                    socials.map((social, index) => (
                        <a 
                        key={index}
                        href={social.link}
                        target='_blank'
                        className="iconContainer">
                            {social.icon}
                        </a>
                    ))
                    }
                    </div>
                </motion.div>
            </motion.div>
            {isOpen &&
            
            <motion.div 
            style={{opacity: menuOpacity, display: isOpen ? 'flex' : "none"}}
            className="navBarSubSection" id='mobileNavLinks'>
                <motion.div
                initial={{y: 60, opacity: 0.5, filter: 'blur(20px)'}}
                animate={{y: 0, opacity: 1, filter: 'blur(0px)'}}
                transition={{ duration: 0.4 }}
                className="mobileLinks">
                    <NavLink to={'/'} className={({isActive, isPending}) => isActive ? 'activeMobileLink' : isPending ? '' : 'mobileLink'}>About</NavLink>
                    <NavLink to={'/gallery'} className={({isActive, isPending}) => isActive ? 'activeMobileLink' : isPending ? '' : 'mobileLink'}>Gallery</NavLink>
                    <NavLink to={'/VFX'} className={({isActive, isPending}) => isActive ? 'activeMobileLink' : isPending ? '' : 'mobileLink'}>VFX</NavLink>
                    <NavLink to={'/contact'} className={({isActive, isPending}) => isActive ? 'activeMobileLink' : isPending ? '' : 'mobileLink'}>Contact</NavLink>
                </motion.div>

            </motion.div>
            }
        </motion.div>
        </>
    )
}