import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
//Style import 
import './Footer.css';
//Icons import 
import {FaSquareXTwitter, FaImdb, FaLinkedin} from 'react-icons/fa6';
import {TbBrandFiverr} from 'react-icons/tb';


export function Footer() {

    const navigate = useNavigate();

    function handleCLick() {
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
        navigate('/contact');
    }    

    //Social Icons and links 
    const socials = [
        {icon: <FaImdb className="footerIcon"/>, link: 'https://m.imdb.com/name/nm13517117/'},
        {icon: <FaLinkedin className="footerIcon"/>, link: 'https://www.linkedin.com/in/davide-d-antonio-18312b92?originalSubdomain=uk'},
        {icon: <TbBrandFiverr className="footerIcon"/>, link: ' https://www.fiverr.com/davedantart'},
        {icon: <FaSquareXTwitter className="footerIcon"/>, link: ' https://twitter.com/davide_dantoni0'}
    ];

    return(
        <motion.div
        initial={{y:200, opacity: 0.5}}
        whileInView={{y: 0, opacity: 1}}
        transition={{duration: 2, type: 'spring'}}
        viewport={{ once: true }}
        className="footerContainer">
            <div className="presentationContainer">
                <motion.span 
                initial={{y:50, opacity: 0.5}}
                whileInView={{y: 0, opacity: 1}}
                transition={{duration: 1, type: 'spring'}}
                viewport={{ once: true }}
                className="presentationTitle">Like what you see?</motion.span>
                <motion.span 
                initial={{y:50, opacity: 0.5}}
                whileInView={{y: 0, opacity: 1}}
                transition={{duration: 2, type: 'spring'}}
                viewport={{ once: true }}
                className="presentationSubtitle">contact me and let's work together</motion.span>
                <motion.div
                initial={{y:50, opacity: 0.5}}
                whileInView={{y: 0, opacity: 1}}
                transition={{duration: 2, type: 'spring'}}
                viewport={{ once: true }}
                onClick={() => handleCLick()}
                className="buttonContainer">
                    <span className="buttonText">
                        About me
                    </span>
                </motion.div>
            </div>
            <motion.div 
            initial={{y:50, opacity: 0.5}}
            whileInView={{y: 0, opacity: 1}}
            transition={{duration: 2, type: 'spring'}}
            viewport={{ once: true }}
            className="socialFooterContainer">
                    {
                        socials.map((social, index) => (
                            <a 
                            key={index}
                            href={social.link}
                            target='_blank'>
                                {social.icon}
                            </a>
                        ))
                    }
                </motion.div>
        </motion.div>
    )
}