import React, { useEffect } from "react";
import { motion, useSpring, useTransform, useViewportScroll } from "framer-motion";
import './NavBar.css';

export function TopButton({expandedProj}) {

    let windowWidth = window.innerWidth;
    const {scrollY} = useViewportScroll();

    const translate = useTransform(scrollY, [0, 120], [200, 0]);
    const translateValue = useSpring(translate);
    const bottomValue = useSpring('24px')

    useEffect(() => {
        expandedProj ? bottomValue.set('-200px') : bottomValue.set('24px')
    },[expandedProj])

    function handleClick() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }

    return(
        <motion.div 
        onClick={() => handleClick()}
        style={{left: (windowWidth/2) - (16*4.5), bottom: bottomValue, translateY: translateValue}}
        className="topButtonContainer">
            <span className="capitalize">
                back to top
            </span>
        </motion.div>
    )
}