export const data = {
    gallery: [
        //{name: 'Davide_Ale', title: '', description: ''},
        {name: 'Nudenoir_v01', title: 'LUST', type: 'Oil on Canvas', description: '40cm x 40cm'},
        {name: 'Nudenoir_v02', title: 'GREED', type: 'Oil on Canvas', description: '40cm x 40cm'},
        {name: 'Nudenoir_v03', title: 'PRIDE', type: 'Oil on Canvas', description: '40cm x 40cm'},
        {name: 'Nudenoir_v04', title: 'SLOT', type: 'Oil on Canvas', description: '40cm x 40cm'},
        {name: 'Nudenoir_v05', title: 'GLUTTONY', type: 'Oil on Canvas', description: '40cm x 40cm'},
        {name: 'Nudenoir_v06', title: 'ENVY', type: 'Oil on Canvas', description: '40cm x 40cm'},
        {name: 'Nudenoir_v07', title: 'WRATH', type: 'Oil on Canvas', description: '40cm x 40cm'},
        {name: 'Stella', title: 'SEASTAR', type: 'Personal Project-Sculpt Study', description: `Modeled, Sculped, Textured, Rendered in Cycles Blender - Composited in Nuke`},
        {name: 'MandaloriaBuddha', title: 'BUDDHAMANDO',type: 'Personal Project-Sculpt Study', description: `Modeled, Sculped, Textured, Rendered in Cycles Blender - Composited in Nuke`},
        {name:'HumanHAnd', title: 'HUMAN RACE', type: 'Personal Project-Sculpt Study', description: `Modeled, Textured, Rendered in Cycles Blender - Composited in Nuke` },
        {name: 'Monst', title: 'FAUN',type: 'Painting Study', description: 'Acrilic on paper'},
        {name: 'Haeiz', title: 'THE KISS', type:'Oil on Canvas', description: 'Replica from Francesco Haiez painting - 112cm X 88cm'},
        {name: 'Nouveau', title: 'FOUR SEASON', type: 'Acrilic on Canvas', description: 'Replica Art Nouveau study by Mucha - 1m x 80cm'},
        {name: 'AlienStudy', title: 'SKETCH', type: 'Pencil on Paper', description: 'Alien study'},
        {name: 'Seahorse', title: 'SKETCH', type: 'Pencil on Paper', description: 'Seaorse sudy'},
        {name: 'SketcMad', title: 'SKETCH', type: 'Pencil on paper', description: 'Study from Roberto Ferri painting'},
        {name: 'Scientist', title: 'DOC', type: 'Personal Project-Sculp Study', description: `Modeled, Sculped, Textured, Rendered in Cycles Blender - Composited in Nuke`},
        {name: 'Orc', title: 'ORCH', type: 'Personal Project-Sculp Study', description: `Modeled, Sculped, Textured, Rendered in Cycles Blender - Composited in Nuke`},
        {name: 'ZombieApocalyps2', title: 'ZOMBIE APOCALYPSE', type: 'Personal Project-Sculp Study', description: `Modeled, Sculped, Textured, Rendered in Cycles Blender - Composited in Nuke`}
    ],
    VFX: [
        {
            name: 'Davide_Comp_Reel_2023_v01.mp4', 
            title: 'Personal Compositing Reel', 
            categories: ['CG/VFX', 'Compositing'],
            description: `This is a personal Compositing showreel, tailored for me by Josh Parks, London based Senior Compositor and Lecturer.
            Under his guidance I have mastered and covered 3D, 2D Projections, ReLighting, CG and 2D Integration, Keying, Particles and DeepCompositing using Nuke. Within this demo reel I showcased my proficiency in working with projections and relighting from a 2D image, along with particle simulation and composition in the first sequence.
            The second shot features a 3D integration and lighting composition of a CG monster.
            The final shot showcases my Keying skills using footage from "Tears of Steel," involving composition of background images,
            integration of muzzle flashes, and final composition.`
        },
        {
            name: 'PolpoCuoreColore.mp4', 
            title: 'Polpo al cuore', 
            categories: ['CG/VFX','Sculpting', 'Compositing'],
            description: `3D replica of a ceramic  sculpture. Lighting, Sculpting, modelling, and render was achieved in Eevee Blender. Composited in Nuke for ArtstudioMisoul, an independent art association. If you haven't done it yet go to check them out at misoulart.it`
        },
        {
            name: 'Space_Ship_Arcade_Keying_SpaceInvaders.mp4', 
            title: 'SpaceShip Arcade', 
            categories: ['VFX','Environment', 'Compositing'],
            description: `Personal project of a Keying process of a shot from ActionVFX. If you haven't done it yet go check them out at actionvfx.com,
            is the largest library of visual effects stock footage and much more.
            In this particular sequence, I seamlessly replaced the background with authentic footage from an arcade space, executing the Compositing, Keying and precise relighting within Nuke.
            `
        },
        {
            name: 'TransmissionSBT.mov', 
            title:'Transmission SBT', 
            categories: ['CG/VFX','Sculpting', 'Compositing'],
            description: `In this personal project sequence I take the opportunity to showcase my skills in sculpting, texturing, lighting, matchmoving
            and 3D Compositing . For this project I modelled, textured, sculpted and rendered  in Blender Cycles,
            transitioning to Nuke for the final matchmove and final 3D composition.`
        },
        {
            name: 'ManoMayfair_nana_high_10tillLate.mp4', 
            title: 'Social Media Reel', 
            categories: ['CG/VFX','Environment', 'Animation', 'Compositing'],
            description: `This project was tailored for an advertisement on social media reel  and is been commissioned by Mano an Italian restaurant/bar in the heart of Mayfair, London. I have used a CG library in Blender for the environment bringing to life a vibrant environment with meticulously designed plants, the strategic utilization of procedural particles and curated stock footage, all been composited using Nuke.
            This collaboration not only serves as a testament to my technical skills but also captures the essence of Mano's distinctive charm and
            atmosphere, seamlessly translated into a captivating visual narrative.`
        },
    ]
}