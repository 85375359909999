import React from "react";
import './MoviePoster.css';

import { PosterTile } from "./PosterTile";

import { postersData } from "./posters";

export function MoviePoster() {

    return(
        <div className="MoviePostersContainer">            
        {
            postersData.map((posterData, index) => (
                <PosterTile
                key={posterData.id}
                posterData={posterData}
                index={index}
                />
                ))
            }
        </div>
    )
}