import React, { useEffect } from "react";
import { motion, useSpring, animate, useMotionValue } from "framer-motion";
import './Loader.css';
import DVFX from '../../Media/Gallery/logo/DVFX.png';

export function Loader({loading, setLoading}) {
    
    const x = useSpring(0, {stiffness: 110, dumping: 60});
    const logoX = useSpring(0);
    let width = window.innerWidth;
    
    useEffect(() => {
        if(loading) {
            animate(x, width, {
                duration: 1.2, 
                type: 'tween', 
                delay: 1.5,
                onComplete: () => setLoading(false)
            })
            animate(logoX, width, {
                duration: 0.6,
                delay: 1.5
            });
        }
    },[loading]);
    
    return(
            loading && 
            <motion.div className="loaderContainer"
            style={{x}}
            >
                <motion.img
                alt='logo'
                initial={{opacity: 0, scale: 0.6}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 1}}
                style={{x: logoX}}
                className="loaderLogo"
                src={DVFX}
                />
            </motion.div>
        
    )
}