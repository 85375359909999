import React, {useEffect, useRef} from "react";
import './Gallery.css'; 
import { animate, motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
//Images import 
import HumanHAnd_preview from '../../Media/Gallery/Preview/HumanHAnd_preview.jpg'
import Davide_Ale_preview from '../../Media/Gallery/Preview/Davide_Ale_preview.jpg'
import MandaloriaBuddha_preview from '../../Media/Gallery/Preview/MandaloriaBuddha_preview.jpg'
import Nudenoir_v01_preview from '../../Media/Gallery/Preview/Nudenoir_v01_preview.jpg'
import Nudenoir_v02_preview from '../../Media/Gallery/Preview/Nudenoir_v02_preview.jpg'
import Nudenoir_v03_preview from '../../Media/Gallery/Preview/Nudenoir_v03_preview.jpg'
import Nudenoir_v04_preview from '../../Media/Gallery/Preview/NudeNoir_v04_preview.jpg'
import Nudenoir_v05_preview from '../../Media/Gallery/Preview/NudeNoir_v05_preview.jpg'
import Nudenoir_v06_preview from '../../Media/Gallery/Preview/NudeNoir_v06_preview.jpg'
import Nudenoir_v07_preview from '../../Media/Gallery/Preview/NudeNoir_v07_preview.jpg'
import Orc_preview from '../../Media/Gallery/Preview/Orc_preview.png'
import Stella_preview from '../../Media/Gallery/Preview/Stella_Preview.png'
import ZombieApocalyps2_preview from '../../Media/Gallery/Preview/ZombieApocalyps2_preview.png'
import AlienStudy_preview from '../../Media/Gallery/Preview/AlienStudy_preview.jpg'
import Haeiz_preview from '../../Media/Gallery/Preview/Haiez_preview.jpg'
import Monst_preview from '../../Media/Gallery/Preview/Monst_preview.jpg'
import Nouveau_preview from '../../Media/Gallery/Preview/Nouveau_preview.jpg'
import Scientist_preview from '../../Media/Gallery/Preview/Scientist_preview.png'
import Seahorse_preview from '../../Media/Gallery/Preview/Seahorse_preview.jpg'
import SketcMad_preview from '../../Media/Gallery/Preview/SketcMad_preview.jpg'

export function GalleryImage({index,selectedId, setSelectedId, setImgPos}) {
    const data = [
        //Davide_Ale_preview,
        Nudenoir_v01_preview,
        Nudenoir_v02_preview,
        Nudenoir_v03_preview,
        Nudenoir_v04_preview,
        Nudenoir_v05_preview,
        Nudenoir_v06_preview,
        Nudenoir_v07_preview,
        Stella_preview,
        MandaloriaBuddha_preview,
        HumanHAnd_preview,
        Monst_preview,
        Haeiz_preview,
        Nouveau_preview,
        AlienStudy_preview,
        Seahorse_preview,
        SketcMad_preview,
        Scientist_preview,
        Orc_preview,
        ZombieApocalyps2_preview
    ];

    const posRef = useRef(null);
    useEffect(() => {
        selectedId !== null && setImgPos((prevState)=> [...prevState, posRef.current.offsetTop]);
        selectedId === null && setImgPos([]);
    },[selectedId])

    return(
        <motion.div 
        ref={posRef}
        className="imageContainer">
            <LazyLoadImage
            loading="lazy"
            onClick={() => setSelectedId(index + 1)}
            className='galleryPost' src={data[index]} />
        </motion.div>
    )
}