export  const postersData = [
    {
        title: 'BeetleJuice 2',
        id: '001',
        platform: 'Theater',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2022-23'
    },
    {
        title: 'Damsel',
        id: '006',
        platform: 'Netflix',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2023'
    },
    {
        title: 'Madame Web',
        id: '002',
        platform: 'Theatre',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2023'
    },
    {
        title: 'Constellation',
        id: '004',
        platform: 'Apple TV',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'TV Series',
        year: '2022-23'
    },
    {
        title: 'Napoleon',
        id: '005',
        platform: 'Theatre',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2022-23'
    },
    {
        title: 'The Book of Clarence',
        id: '003',
        platform: 'Apple TV',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2023'
    },
    {
        title: 'Secret Invasion',
        id: '007',
        platform: 'Disney +',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2022-23'
    },
    {
        title: 'The Sandman',
        id: '008',
        platform: 'Netflix',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'S1 E4',
        year: '2021-22'
    },
    {
        title: 'Thor - Love and Thunder',
        id: '009',
        platform: 'Disney +',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2021-22'
    },
    {
        title: 'Wendell & Wild',
        id: '010',
        platform: 'Netflix',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2021-22'
    },
    {
        title: 'Fantastic Beasts',
        id: '011',
        platform: 'Netflix',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'Feature Film',
        year: '2021'
    },
    {
        title: 'Bridgerton',
        id: '012',
        platform: 'Netflix',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'S1 E 2,3',
        year: '2020-21'
    },
    {
        title: 'The Man Who Fel To Earth',
        id: '013',
        platform: 'Showtime',
        role: 'Roto-Prep Artist',
        studio: 'One Of Us',
        episodes: 'S1 E 1,2,3',
        year: '2021'
    },
    {
        title: 'The Northman',
        id: '014',
        platform: 'Amazon Prime',
        role: 'Roto-Prep Artist',
        studio: 'BlueBolt',
        episodes: 'Feature Film',
        year: '2021'
    },
    {
        title: 'The Great 2',
        id: '015',
        platform: 'Amazon Prime',
        role: 'Roto-Prep Artist',
        studio: 'BlueBolt',
        episodes: 'S1 E 1',
        year: '2020'
    },
    {
        title: 'Cursed',
        id: '016',
        platform: 'Netflix',
        role: 'Junior Compositor',
        studio: 'Encore Post',
        episodes: 'S1 E1',
        year: '2020'
    },
    {
        title: 'Flack',
        id: '017',
        platform: 'Amazon Prime',
        role: 'Junior Compositor',
        studio: 'Encore Post',
        episodes: 'S2 E2',
        year: '2020'
    },
    {
        title: 'The Great',
        id: '018',
        platform: 'Hulu',
        role: 'Junior Compositor',
        studio: 'Encore Post',
        episodes: 'S1 E2',
        year: '2020'
    },
    {
        title: 'Our Girl',
        id: '019',
        platform: 'BBC',
        role: 'Junior Compositor',
        studio: 'Encore Post',
        episodes: 'S2',
        year: '2019'
    },
    {
        title: 'Quiz',
        id: '020',
        platform: 'ITV',
        role: 'Junior Compositor',
        studio: 'Encore Post',
        episodes: 'Feature Film',
        year: '2019'
    },
]