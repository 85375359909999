import React, {useEffect, useState} from "react";
import { motion, useSpring } from 'framer-motion'
import './ContactSection.css';
import emailjs from '@emailjs/browser';

//Loading spinner import 
import { RotatingLines } from  'react-loader-spinner'
import Frame1 from '../../Media/assets/Frame_1.gif'

export function ContactForm() {

    //EmailJS 

    const [name,setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(null);
    const [disable, setDisable] = useState(false);

    //EmailJS params
    const templateParams = {
        from_name: name,
        from_email: email,
        message: message,
    };
    const serviceID = 'service_yiizoe4';
    const templateID = 'template_111o4fx';
    const publickKey = '3WlE4hBj_ys55ZDF4';

    //Send email using emailJS
    function handleSubmit(e) {
        e.preventDefault();
        setDisable(true);
        emailjs
        .send( serviceID, templateID, templateParams, publickKey)
        .then(
          (response) => {
            console.log('Email sent succesfully!', response.status, response.text);
            setSuccess(true);
            setDisable(false);
          }
        ).catch((err) => {
            console.log('FAILED...', err);
            setSuccess(false);
            setDisable(false);
        });
    }

    const translateY = useSpring(0,{ stiffness: 100, damping: 100});

    useEffect(() => {
        if(success === true) {
            setName('');
            setEmail('');
            setMessage('');
            translateY.set(-1000);
        } else if(success === false) {
            translateY.set(0);
        }
    },[success])
    
    return(
        <div className="formContainer">
            {
                !success &&
                <>
                <div className="formTitleContainer">
                <span className="formTitle">Drop a Line</span>
                </div>
                <form
                onSubmit={(e) => handleSubmit(e)}>
    
                    <motion.div
                    style={{scale: 1, y: translateY, }}
                    className="inline">
                        <div  id="name" className="section">
                            <span className="label">Your Name:</span>
                            <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="input" type="text" name="name" required/>
                        </div>
                        <div  id="mail"  className="section">
                            <span className="label">Email:</span>
                            <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input" type="text" name="mail" required/>
                        </div>
                    </motion.div>
                    <motion.div
                    style={{scale: 1, y: translateY, }}
                    className="textAreaSextion">
                        <span className="label">Message:</span>
                        <textarea 
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="textArea" type="text" name="comment" required></textarea>
                    </motion.div>
                    <motion.div
                    style={{y: translateY}}
                    className="formButtons">
                        {
                            !disable && 
                            <input disabled={disable} className={disable ? 'disabledFormButton': 'formButton'} type="submit" value={'Send'}/>
                        }
                        {
                            disable &&
                            <RotatingLines
                            strokeColor="lightgrey"
                            strokeWidth="4"
                            animationDuration="1"
                            width="30"
                            visible={disable}
                            />
                        }
                    </motion.div>
                </form>
                </>
            }
            {
                success && 
                <motion.div 
                initial={{opacity: 0, scale: 0.7}}
                animate={{opacity: 1, scale: 1}}
                transition={{type:'spring', duration: 1.5}}
                className="successContainer"
                >
                    <motion.h2 
                        initial={{y: 150}}
                        animate={{y:0}}
                        transition={{duration: 1.2, type: 'spring'}}
                        className="VFXTitle">Thank you!
                    </motion.h2>    
                    <motion.span className="successMessage">Your message was sent successfully.</motion.span>
                    <img src={Frame1} alt="success animation"/>
                </motion.div>
            }
        </div>
    )
}