import React, {useEffect} from "react";
import { useRef } from "react";
import { motion, useSpring, animate} from "framer-motion"
import { useNavigate } from "react-router-dom";
//Style import
import './Home.css';
//Media import
import Banner_trailer from '../../Media/Gallery/trailer/Banner_trailer.mp4';
//Icon import
import { FaArrowDown } from "react-icons/fa";
//Component import
import { VfxSection } from "../VFX/VfxSection";
import { MoviePoster } from "../MoviePosters/MoviePosters";

export function Home() {

    const navigate = useNavigate();

    function handleClick() {
        navigate('/gallery');
    }

    const mainRef = useRef(null);

    function handleScroll() {
        const {offsetTop} = mainRef.current;
        window.scrollTo({
            left:0,
            top: offsetTop,
            behavior: 'smooth'
        })
    }

    return(
        <motion.div
        className="homeContainer"
        initial={{opacity:0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.4}}
        >
            <div className="trailerContainer">
                <video 
                playsInline
                className="bannerVideo"
                loop
                autoPlay
                muted
                >
                    <source src={Banner_trailer} type={'video/mp4'}/>
                </video>
                <motion.div 
                animate={{y: 5}}
                transition={{delay: 1, duration: 1.5, repeat: 5, repeatType: 'mirror'}}
                onClick={() => handleScroll()}
                className="latestContainer">
                    <span className="latestText">Latest Work</span>
                    <FaArrowDown style={{fontSize: 25, fontWeight: 800}}/>
                </motion.div>
            </div>
            <div ref={mainRef} className="presentationContainer">
                <span className="presentationTitle">Davide D'Antonio | VFX Artist</span>
                <span className="presentationSubtitle">Design, craft & creativity for movies, games, advertising and more</span>
            </div>
            <MoviePoster />
            <div 
            onClick={() => {handleClick()}} 
            className="showMoreContainer">
                <span className="longButtonText">show more works</span>
            </div>
        </motion.div>
    )
}