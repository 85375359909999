import React, { useState, useEffect } from "react";
import { motion, useSpring } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
//Loading spinner import 
import { RotatingLines } from  'react-loader-spinner'

export function Tile({element, index, data}) {

    const navigate = useNavigate();

    const [hover,setHover] = useState(false);
    const translateTitle = useSpring(-100,{ stiffness: 110, damping: 15 });
    const opacity = useSpring(100,{ stiffness: 110, damping: 15 });

    

    useEffect(() => {
        if(hover){
            translateTitle.set(0)
            opacity.set(1);
        } else if(!hover) {
            translateTitle.set(-100)
            opacity.set(0);
        }
    },[hover])

    return(
        <motion.div
        style={{backgroundImage: `url(${element[0]})` }}
        onClick={() => navigate(`/VFX/${index}`, {state: {index: index, element: element, data: data}})}
        className="tileContainer">
            <motion.div 
            initial={{opacity: 0}}
            whileHover={{opacity: 1}}
            whileTap={{opacity: 1}}
            style={{backgroundImage: `url(${element[1]})` }}
            className="opacityFilter">
                <div 
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className="cardContainer">
                    <motion.div 
                    style={{y: translateTitle, opacity}}
                    className='tileTitleContainer'>
                        <span className="tileTitle">{data.title}</span>
                    </motion.div>
                </div>
            </motion.div>
        </motion.div>
    )
}